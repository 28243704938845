import React from 'react';
import CesiumViewer from '../../components/cesium_viewer_depended/cesiumviewer/CesiumViewer';
import ProjectViewer from './projectviewer/ProjectViewer';

import './IFrame.scss';

export default function IFrame() {
    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <CesiumViewer>
                <ProjectViewer />
            </CesiumViewer>
            
            <a href="https://csb.gov.tr/" target="_blank" rel="noreferrer">
                <img className="firmlogo" src="cevresehircilikbakanligi.png" alt="Çevre Şehircilik Bakanlığı" />
            </a>

            <div className="ankageo-logo">
                <img className="logo-1" alt="Ankageo" src="ankageo-logo.png" />
                <img className="logo-2" alt="Ankageo" src="ankageo-logo-2.png" />
            </div>
        </div>
    )
}
