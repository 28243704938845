// import React from 'react';
// import logo from './logo.svg';
import './App.scss';
import IFrame from './routes/iframe/IFrame';

function App() {
  return (
    <IFrame />
  );
}

export default App;
