import { Card } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import './IFramePanel.scss';
interface Props {

}
export default function IFramePanel(props: PropsWithChildren<Props>) {
    return (
        <div className="IFramePanel">
            <Card>
                {props.children}
            </Card>
        </div>
    )
}
