import React, {
    useState,
    useEffect,
    useContext,
    PropsWithChildren
} from 'react'
import { RestApi } from '../../../utils/apirequester';
import { CesiumViewerContext } from '../cesiumviewer/CesiumViewer';

interface Props {
    visible: boolean;
    double_side_render: boolean;
    maximum_screen_space_error: number;
    tilesetId: number;
    style: string | null;
    offset_x: number;
    offset_y: number;
    offset_z: number;
    zoomTilesetTriggerValue?: number;
    url?: string;
}
export default function Cesium3DTileset(props: PropsWithChildren<Props>) {
    const viewer = useContext(CesiumViewerContext)
    const [tileset, setTileset] = useState<Cesium.Cesium3DTileset | null>(null);
    useEffect(() => {
        if (!viewer) {
            return;
        }
        // console.log('process.env.REACT_APP_TILESET_URL', process.env.REACT_APP_TILESET_URL)
        const url = props.url || RestApi.getBaseUrl() + `/api/v1/static/tileset/${props.tilesetId}/tileset.json`;
        // const url = `https://yanginanaliz.csb.gov.tr/static_data/tileset/${props.tilesetId}/tileset.json`;
        console.log('URL', url);

        const t = new Cesium.Cesium3DTileset({
            url,
        });
        viewer.scene.primitives.add(t);
        setTileset(t);
        return () => {
            if (!viewer) {
                return;
            }
            viewer.scene.primitives.remove(t);
        }
    }, [viewer]);

    useEffect(() => {
        updateMaximumScreenSpaceError();
        updateVisible();
        updateDoubleSideRender();
        updateStyle();
        updateOffset();
    }, [tileset])

    useEffect(updateVisible, [props.visible]);
    useEffect(updateMaximumScreenSpaceError, [props.maximum_screen_space_error]);
    useEffect(updateStyle, [props.style]);
    useEffect(updateDoubleSideRender, [props.double_side_render]);
    useEffect(() => { updateOffset() }, [props.offset_x, props.offset_y, props.offset_z]);

    useEffect(() => { zoomToTileset() }, [props.zoomTilesetTriggerValue]);

    function updateStyle() {
        if (!tileset) {
            return;
        }
        if (props.style === null) {
            (tileset as any).style = null;
        } else {
            tileset.style = new Cesium.Cesium3DTileStyle({
                color: `color('${props.style}')`
            })
        }
    }
    function updateMaximumScreenSpaceError() {
        if (!tileset) {
            return;
        }
        tileset.maximumScreenSpaceError = props.maximum_screen_space_error;
    }
    function updateVisible() {
        if (!tileset) {
            return;
        }
        tileset.show = props.visible;
        updateStyle();
    }
    function updateDoubleSideRender() {
        if (!tileset) {
            return;
        }
        (tileset as any).backFaceCulling = !props.double_side_render;
    }
    async function zoomToTileset() {
        if (!tileset) {
            return;
        }
        if(props.zoomTilesetTriggerValue === 0) {
            return;
        }
        await tileset.readyPromise;
        viewer?.scene.camera.flyToBoundingSphere(tileset.boundingSphere);
    }
    async function updateOffset() {
        if (!tileset) {
            return;
        }
        const x = props.offset_x;
        const y = props.offset_y;
        const z = props.offset_z;
        await tileset.readyPromise;
        const boundingSphere = tileset.boundingSphere;
        const cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center);
        const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
        const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude + x, cartographic.latitude + y, z);
        const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
    }
    return null;
}
