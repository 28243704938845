import React, { useRef, useEffect, useState, PropsWithChildren } from 'react'
import './CesiumViewer.scss';

export const CesiumViewerContext = React.createContext<Cesium.Viewer | null>(null);

interface Props {
    callback?: (viewer: Cesium.Viewer) => void;
}
export default function CesiumViewer(props: PropsWithChildren<Props>) {
    const inputEl = useRef(document.createElement('div'));
    const [viewer, setViewer] = useState<Cesium.Viewer | null>(null)
    // console.log('HAYDARRR')
    useEffect(() => {
        // console.log('HAYDA')
        const v = new Cesium.Viewer(inputEl.current, {
            animation: false,
            homeButton: false,
            navigationHelpButton: false,
            infoBox: false,
            geocoder: false,
            baseLayerPicker: false,
            sceneModePicker: false,
            fullscreenButton: false,
            selectionIndicator: false,
            timeline: false,
            navigationInstructionsInitiallyVisible: false,
            imageryProvider: new Cesium.UrlTemplateImageryProvider({
                url: 'https://mt3.google.com/vt/lyrs=s@113&hl=tr&x={x}&y={y}&z={z}',
                maximumLevel: 22,
                minimumLevel: 1
            })
        });
        (v as any)._cesiumWidget._creditContainer.style.display = "none";
        (window as any).viewer = v;
        setViewer(v);
        if (props.callback) {
            props.callback(v);
        }

        const height = 0;
        console.log('height', height);
        const position = Cesium.Cartesian3.fromDegrees(
            // 31.43385,
            // 36.755667,
            31.386651,
            36.763685, 

            height
        );
        const heading = Cesium.Math.toRadians(135);
        const pitch = 0;
        const roll = 0;
        const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
        const orientation = Cesium.Transforms.headingPitchRollQuaternion(
            position,
            hpr
        );

        const url = '/csb_bina.glb';
        const entity = v.entities.add({
            name: url,
            position: position,
            orientation: orientation,
            model: {
                uri: url,
                // minimumPixelSize: 128,
                // maximumScale: 20000,
                scale: 3,
            },
        } as any);
        (window as any).entity = entity;
        console.log('entity', entity);
        // v.trackedEntity = entity;
        // const kml = Cesium.KmlDataSource.load('/csb_bina.kmz');
        // const kml = Cesium.KmlDataSource.load('/Model.kmz');
        // (window as any).kml = kml;
        // v.dataSources.add(kml);
    }, []);
    return (
        <div className="CesiumViewer">
            <CesiumViewerContext.Provider value={viewer}>
                <div ref={inputEl} className="map"></div>
                {props.children}
            </CesiumViewerContext.Provider>
        </div>
    )
}
