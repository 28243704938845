import { DataGeojsonProject, DataTerrain, IProject, ITilesetExtended } from '../../utils/models';
import { ActionProjectEditUpdateGeojson, ActionProjectEditUpdateTileset } from '../actions/project_edit';
import { DefaultAction } from '../util';
interface State {
    project: IProject | undefined;
    tilesetList: ITilesetExtended[];
    geojsonList: DataGeojsonProject[];
    terrainList: DataTerrain[];
}
const initialState = {
    project: undefined,
    tilesetList: [],
    geojsonList: [],
    terrainList: [],
}
export const ProjectEditReducer = (state: State = initialState, action: DefaultAction): State => {
    if (action.type === 'PROJECT_EDIT_SET_PROJECT') {
        return Object.assign({}, state, {
            project: action.payload
        })
    } else if (action.type === 'PROJECT_EDIT_UPDATE_PROJECT') {
        const newProject = Object.assign({}, state.project, action.payload);
        return Object.assign({}, state, {
            project: newProject
        })
    } else if (action.type === 'PROJECT_EDIT_SET_TILESETLIST') {
        return Object.assign({}, state, {
            tilesetList: action.payload
        })
    } else if (action.type === 'PROJECT_EDIT_SET_GEOJSONLIST') {
        return Object.assign({}, state, {
            geojsonList: action.payload
        })
    } else if (action.type === 'PROJECT_EDIT_SET_TERRAINLIST') {
        return Object.assign({}, state, {
            terrainList: action.payload
        })
    } else if (action.type === 'PROJECT_EDIT_UPDATE_TILESET') {
        const a = action as ActionProjectEditUpdateTileset;
        const tilesetIndex = state.tilesetList.findIndex(t => t.id === a.payload.id);
        const tileset = state.tilesetList[tilesetIndex];
        if (!tileset) {
            return state;
        }
        const newTileset = Object.assign({}, tileset, a.payload.properties);
        const newTilesetArray = state.tilesetList.concat();
        newTilesetArray[tilesetIndex] = newTileset;
        return Object.assign({}, state, {
            tilesetList: newTilesetArray
        });
    } else if (action.type === 'PROJECT_EDIT_UPDATE_GEOJSON') {
        const a = action as ActionProjectEditUpdateGeojson;
        const geojsonIndex = state.geojsonList.findIndex(g => g.id === a.payload.id);
        const geojson = state.geojsonList[geojsonIndex];
        if (!geojson) {
            return state;
        }
        const newTileset = Object.assign({}, geojson, a.payload.properties);
        const newGeojsonArray = state.geojsonList.concat();
        newGeojsonArray[geojsonIndex] = newTileset;
        return Object.assign({}, state, {
            geojsonList: newGeojsonArray
        });
    }
    return state;
}
