import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { DataGeojsonProject, IProject, ITileset, ITilesetExtended } from '../../../utils/models';
import { RestApi } from '../../../utils/apirequester';
import IFramePanel from '../iframe_panel/IFramePanel';
import IFrameTileset from '../iframe_tileset/IFrameTileset';
import { useSelector, useDispatch } from 'react-redux';
import Shadow from '../../../components/cesium_viewer_depended/shadow/Shadow';

import './ProjectViewer.scss';
import { ReduxState } from '../../../redux/store';
import { actionProjectEditSetGeojsonList, actionProjectEditSetProject, actionProjectEditSetTilesetList, actionProjectEditUpdateGeojson, actionProjectEditUpdateTileset, actionProjectTilesetZoom } from '../../../redux/actions/project_edit';
import Cesium3DTileset from '../../../components/cesium_viewer_depended/cesium3dtileset/Cesium3DTileset';
import CameraPosition from '../../../components/cesium_viewer_depended/cameraposition/CameraPosition';
import GeojsonLoader from '../../../components/cesium_viewer_depended/geojsonloader/GeojsonLoader';
import Terrain from '../../../components/cesium_viewer_depended/terrain/Terrain';
import { Button } from '@material-ui/core';
import { CesiumViewerContext } from '../../../components/cesium_viewer_depended/cesiumviewer/CesiumViewer';


// async function getIProject(): Promise<IProject> {
//     return {
//         "id": 22,
//         "name": "manavgat",
//         "camera_position": {
//             "crs": {
//                 "type": "name", "properties": { "name": "EPSG:4326" }
//             },
//             "type": "Point",
//             "coordinates": [31.359223565225467, 36.866907819547244, 124.85336312925752]
//         },
//         "camera_up": {
//             "type": "Point",
//             "coordinates": [0.9253055360480419, 0.33070760492025264, 0.1855859504562672]
//         },
//         "camera_direction": {
//             "type": "Point",
//             "coordinates": [0.2803083207401699, -0.26684188500582956, -0.922075188761937]
//         },
//         "shadow": false,
//         "iframe_panel": true,
//         "createdAt": "2021-07-30T22:24:15.827Z",
//         "updatedAt": "2021-07-31T07:59:21.715Z",
//         "TerrainId": null
//     }
// }

// async function getITilesetExtendedArray(): Promise<ITileset[]> {
//     return [
//         {
//           "id": 54,
//           "name": "Kalemler Mh. 1",
//           "offset_x": 0,
//           "offset_y": 0,
//           "offset_z": -100,
//           "maximum_screen_space_error": 1,
//           "double_side_render": false,
//           "visible": true,
//           "style": null,
//           "createdAt": "2021-08-01T12:04:48.756Z",
//           "updatedAt": "2021-08-01T16:32:41.975Z",
//           "ProjectId": 22,
//           "TilesetId": 43,
//           "Tileset": {
//             "id": 43,
//             "name": "Kalemler Mh. 2",
//             "state": "verified",
//             "createdAt": "2021-08-01T11:55:51.348Z",
//             "updatedAt": "2021-08-01T18:08:58.748Z"
//           }
//         },
//         {
//           "id": 55,
//           "name": "Kalemler Mh. 2",
//           "offset_x": 0,
//           "offset_y": 0,
//           "offset_z": -100,
//           "maximum_screen_space_error": 1,
//           "double_side_render": false,
//           "visible": true,
//           "style": null,
//           "createdAt": "2021-08-01T12:10:49.175Z",
//           "updatedAt": "2021-08-01T16:32:44.540Z",
//           "ProjectId": 22,
//           "TilesetId": 44,
//           "Tileset": {
//             "id": 44,
//             "name": "Kalemler Mh. 1",
//             "state": "verified",
//             "createdAt": "2021-08-01T12:09:45.994Z",
//             "updatedAt": "2021-08-01T18:07:17.771Z"
//           }
//         },
//         {
//           "id": 56,
//           "name": "Kalemler Mh. 3",
//           "offset_x": 0,
//           "offset_y": 0,
//           "offset_z": -100,
//           "maximum_screen_space_error": 1,
//           "double_side_render": false,
//           "visible": true,
//           "style": null,
//           "createdAt": "2021-08-01T15:52:54.780Z",
//           "updatedAt": "2021-08-01T15:57:11.366Z",
//           "ProjectId": 22,
//           "TilesetId": 45,
//           "Tileset": {
//             "id": 45,
//             "name": "Kalemler Mh. 3",
//             "state": "verified",
//             "createdAt": "2021-08-01T15:46:20.527Z",
//             "updatedAt": "2021-08-01T15:51:51.985Z"
//           }
//         },
//         {
//           "id": 57,
//           "name": "Kalemler Mh. 4",
//           "offset_x": 0,
//           "offset_y": 0,
//           "offset_z": -100,
//           "maximum_screen_space_error": 1,
//           "double_side_render": false,
//           "visible": true,
//           "style": null,
//           "createdAt": "2021-08-01T16:32:19.091Z",
//           "updatedAt": "2021-08-01T16:33:58.260Z",
//           "ProjectId": 22,
//           "TilesetId": 46,
//           "Tileset": {
//             "id": 46,
//             "name": "Kalemler Mh. 4",
//             "state": "verified",
//             "createdAt": "2021-08-01T16:31:07.955Z",
//             "updatedAt": "2021-08-01T16:35:00.603Z"
//           }
//         }
//       ]
// }


// async function getIDataGeojsonProjectArray(): Promise<DataGeojsonProject[]> {
//     return [ ]
// }
export default function ProjectViewer() {
    const projectId = 22;
    const viewer = useContext(CesiumViewerContext)
    const project = useSelector<ReduxState>((state => state.projectEdit.project)) as IProject
    const tilesetList = useSelector<ReduxState>((state => state.projectEdit.tilesetList)) as ITilesetExtended[];
    const geojsonList = useSelector<ReduxState>((state => state.projectEdit.geojsonList)) as DataGeojsonProject[];


    const dispatch = useDispatch();
    async function loadData() {
        const [project, tilesetList, geojsonList] = await Promise.all([
            RestApi.get<IProject>(`/api/v1/project/${projectId}`),
            RestApi.get<ITileset[]>(`/api/v1/project/${projectId}/tileset`),
            RestApi.get<DataGeojsonProject[]>(`/api/v1/project/${projectId}/geojson`),
        ]);
        dispatch(actionProjectEditSetProject(project));
        dispatch(actionProjectEditSetGeojsonList(geojsonList));
        dispatch(actionProjectEditSetTilesetList(
            tilesetList.map(t => ({ zoomTilesetTriggerValue: 0, ...t })).sort((a,b) => a.name.localeCompare(b.name))
        ));
    }
    function changeVisibility(id: number, visible: boolean) {
        dispatch(actionProjectEditUpdateTileset(id, {
            visible
        }))
    }
    function changeGeojsonVisibility(id: number, visible: boolean) {
        dispatch(actionProjectEditUpdateGeojson(id, {
            visible
        }))
    }
    function onZoomTileset(t: ITilesetExtended) {
        dispatch(actionProjectTilesetZoom(t.id, t.zoomTilesetTriggerValue + 1))
    }
    useEffect(() => {
        loadData()
    }, [projectId])

    if (!project) {
        return null;
    }
    return (
        <div className="ProjectViewer">
            {project.iframe_panel ?
                <IFramePanel>
                    {tilesetList.map(t => <IFrameTileset
                        key={t.id}
                        name={t.name}
                        defaultVisible={t.visible}
                        onVisibleChange={(c) => changeVisibility(t.id, c)}
                        onZoom={() => onZoomTileset(t)}
                    />)}
                    {geojsonList.map(g => <IFrameTileset
                        key={g.id}
                        name={g.name}
                        defaultVisible={g.visible}
                        onVisibleChange={(v) => changeGeojsonVisibility(g.id, v)}
                        onZoom={() => { }}
                    />)}
                    <Button onClick={() => viewer!.flyTo((window as any).entity)}>Binaya Git</Button>
                </IFramePanel>
                : null
            }
            {project ? <>
                <Shadow shadow={project.shadow}></Shadow>
                <CameraPosition
                    camera_direction={project.camera_direction}
                    camera_up={project.camera_up}
                    camera_position={project.camera_position}
                />
                {tilesetList.map(t =>
                    <Cesium3DTileset
                        key={t.id}
                        tilesetId={t.TilesetId}
                        url={t.Tileset.url}
                        visible={t.visible}
                        maximum_screen_space_error={t.maximum_screen_space_error}
                        double_side_render={t.double_side_render}
                        style={t.style}
                        offset_x={t.offset_x}
                        offset_y={t.offset_y}
                        offset_z={t.offset_z}
                        zoomTilesetTriggerValue={t.zoomTilesetTriggerValue}
                    />)
                }
                {geojsonList.map(g =>
                    <GeojsonLoader
                        key={g.id}
                        geojsonId={g.geojson_id}
                        visible={g.visible}
                        classification={g.classify}
                        style={g.style}
                    />)
                }
                <Terrain id={project.TerrainId} />
            </> : null}
        </div>
    )
}
